<template>
  <div>
    <a-card>
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="基础设置">
          <a-form-model
            ref="form"
            :model="rowData"
            :rules="formRule"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="姓名" prop="name">
                  <a-input v-model="rowData.name"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="性别" prop="sex">
                  <a-radio-group v-model="rowData.sex">
                    <a-radio :value="1">男</a-radio>
                    <a-radio :value="2">女</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="头像">
                  <QiniuUpload type="image" :value.sync="rowData.headPortrait"></QiniuUpload>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="出生年月" :autoLink="false" ref="test" prop="birthday">
                  <DatePicker :startTime.sync="rowData.birthday"></DatePicker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="联系电话" prop="mobile">
                  <a-input v-model="rowData.mobile" placeholder="请输入手机号"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="邮箱" prop="email">
                  <a-input v-model="rowData.email"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="居住地址">
                  <PCD
                    ref="pcd"
                    placeholder="请选择区域"
                    :province.sync="rowData.addresses.province"
                    :city.sync="rowData.addresses.city"
                    :district.sync="rowData.addresses.county"
                    :provinceId.sync="rowData.addresses.provinceId"
                    :cityId.sync="rowData.addresses.cityId"
                    :districtId.sync="rowData.addresses.countyId"
                  >
                  </PCD>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="详细地址">
                  <a-textarea :max-length="50" v-model="rowData.addresses.address" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="紧急联系人">
                  <a-input v-model="rowData.connectorName"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="紧急联系人电话" prop="connectorPhone">
                  <a-input v-model="rowData.connectorPhone"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <div class="footer-bts">
            <!-- <a-button type="default" @click="onCancel()">取消</a-button> -->
            <a-button v-if="saveEnable" type="primary" @click="onSubmit">保存</a-button>
            <a-button v-if="resetEnable" type="primary" @click="resetForm">重置</a-button>
          </div>
        </a-tab-pane>

        <a-tab-pane key="2" tab="安全设置" force-render>
          <div class="set">
            <div>账号密码</div>
            <a v-if="editEnable" href="#" @click="handleSet">修改</a>
          </div>
          <a-modal v-model="visible" title="修改密码" @ok="handleOk" @cancel="handleCancel">
            <a-form-model
              ref="ruleFormRefs"
              :model="ruleForm"
              :rules="rulesPasswork"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-form-model-item label="旧密码" prop="oldPwd">
                <a-input v-model="ruleForm.oldPwd" type="password" placeholder="请输入旧密码" />
              </a-form-model-item>
              <a-form-model-item label="新密码" prop="newPwd">
                <a-input v-model="ruleForm.newPwd" type="password" placeholder="请输入新密码" />
              </a-form-model-item>
              <!-- <a-form-model-item label="确认密码" prop="confirmPwd">
                <a-input v-model="ruleForm.confirmPwd" type="password" placeholder="请输入确认密码" />
              </a-form-model-item> -->
            </a-form-model>
          </a-modal>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import {checkPermission} from '@/utils/permissions'
export default {
  data() {
    return {
      visible: false,
      selfId:this.$store.getters.userInfo.id,
      rowData: {
        name:'',
        sex:'',
        birthday:'',
        headPortrait:'',
        mobile:'',
        email:'',
        connectorPhone:'',
        connectorName:'',
        addresses: {},
      },
      ruleFormParams:{},
      ruleForm: {},
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        mobile: [
          { required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', trigger: 'blur' },
        ],
        connectorPhone:[
          { required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', trigger: 'blur' },
        ],
        email: [
          {
            pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
            message: '请输入正确的邮箱地址',
            trigger: 'blur',
          },
        ],
        birthday: [{ required: true, message: '请选择出生年月', trigger: 'change' }],
      },
      // 修改密码
      rulesPasswork: {
         oldPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { validator: validatePass1, trigger: 'blur' },
        ],
        newPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { validator: validatePass, trigger: 'blur' },
        ],
      },
      saveEnable: checkPermission('personal:save'),
      resetEnable: checkPermission('personal:reset'),
      editEnable: checkPermission('personal:edit'),
    }
  },
  
  methods: {
    // 修改密码
    handleSet() {
      this.visible = true
    },
    // 取消弹框
    handleCancel() {
      this.$refs.ruleFormRefs.resetFields()
      this.visible = false
    },
    handleOk() {     
      this.$refs.ruleFormRefs.validate((valid) => {
        if (!valid) {
          return
        }
        this.ruleFormParams.oldPwd = this.ruleForm.oldPwd,
        this.ruleFormParams.newPwd = this.ruleForm.newPwd
         this.axios.post('/api/base/system/dealer/manager/updateDealerPassword', this.ruleFormParams).then((res) => { 
          if (res.code == 200) {
            this.$message.success(res.message)
            this.visible = false
            this.ruleForm = {}
          } else {
            this.$message.error(res.message)
          }
         })
      })
      
    },

    // 重置表单
    resetForm() {
      this.$refs.form.resetFields()
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
      let addresses = []
      addresses.push({
        provinceId: this.rowData.addresses.provinceId,
        province: this.rowData.addresses.province,
        cityId: this.rowData.addresses.cityId,
        city: this.rowData.addresses.city,
        countyId: this.rowData.addresses.countyId,
        county: this.rowData.addresses.county,
        address: this.rowData.addresses.address,
      })
      this.rowData.addresses = addresses
      // console.log(this.rowData)
        this.axios.post('/api/base/system/dealer/manager/editInfo', this.rowData).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.getPersonal()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    getPersonal(){
      this.axios.get('/api/base/system/dealer/manager/getMyInfo')
      .then(res => {
        this.rowData.name = res.body.name
        this.rowData.headPortrait = res.body.headPortrait
        this.rowData.mobile = res.body.mobile
        this.rowData.email = res.body.email
        this.rowData.birthday = res.body.birthday
        this.rowData.sex = res.body.sex
        this.rowData.connectorName = res.body.connectorName
        this.rowData.connectorPhone = res.body.connectorPhone
        // this.rowData.connectors = res.body.connectors[0]
        if(res.body.addresses != null && res.body.addresses.length != 0) {
              this.$set(this.rowData.addresses, 'provinceId', res.body.addresses[0].provinceId)
              this.$set(this.rowData.addresses, 'province', res.body.addresses[0].province)
              this.$set(this.rowData.addresses, 'cityId', res.body.addresses[0].cityId)
              this.$set(this.rowData.addresses, 'city', res.body.addresses[0].city)
              this.$set(this.rowData.addresses, 'countyId', res.body.addresses[0].countyId)
              this.$set(this.rowData.addresses, 'county', res.body.addresses[0].county)
              this.$set(this.rowData.addresses, 'address', res.body.addresses[0].address)
              this.$nextTick(() => {
                this.$refs.pcd.setPCD()
              })
            }
            
      
      })
    }
  },
  created() {
    this.getPersonal()
  },
}
</script>
<style lang="scss">
.footer-bts {
  text-align: center;
}
.set {
  margin-top: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
</style>
